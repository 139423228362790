








































































import { Component, Prop } from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '../misc/ErrorMessageHandler.mixin';
import { mixins } from 'vue-class-component';
import { integer, maxValue, minValue, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { namespace } from 'vuex-class';
import Driver from '@/models/Driver';
import { transactionStoreActions } from '@/store/transaction.store';
import Transaction from '@/models/Transaction';
import { PaymentMethod } from '@/enum/PaymentMethod.enum';

const TransactionStore = namespace('transactions');

interface PaymentMethodInterface {
    paymentMethod: PaymentMethod,
    text: string
}

@Component({
    mixins: [validationMixin],
    validations: {
        description: {},
        paymentMethod: { required },
        newAmount: { required, integer, minValue: minValue(1), maxValue: maxValue(10000) }
    }
})
export default class AddTransactionComponent extends mixins(ErrorMessageHandlerMixin) {
    @Prop({ required: true })
    public driver!: Driver;

    @TransactionStore.Action(transactionStoreActions.CREATE)
    private createTransactionAction!: (transaction: Transaction) => Promise<Transaction>;

    private paymentMethodItems: PaymentMethodInterface[] = [
        { paymentMethod: PaymentMethod.CASH, text: 'Cash' },
        { paymentMethod: PaymentMethod.E_WALLET, text: 'E-Wallet' },
        { paymentMethod: PaymentMethod.PAY_TODAY, text: 'PayToday' },
        { paymentMethod: PaymentMethod.PAY2SELL, text: 'Pay2Sell' },
        { paymentMethod: PaymentMethod.SPEED_POINT, text: 'Speedpoint' }
    ];

    private paymentMethod: PaymentMethod | null = null;

    /**
     * amount that should be added to the users credit
     * @private
     */
    private newAmount: string = '';

    /**
     * optional description for the transaction
     * @private
     */
    private description: string = '';

    /**
     * checks if everything was entered correctly
     */
    public validData = false;

    /**
     * flag to show some loading indicator on api request
     */
    public isLoading: boolean = false;

    /**
     * creates a new transaction by using the entered values
     * @private
     */
    private async createTransaction() {
        this.$v.$touch();
        if (this.$v.$invalid || !this.$getCurrentAccount()?.id) {
            return;
        }

        try {
            this.isLoading = true;
            const transaction = Transaction.parseFromObject({
                description: this.description,
                amount: parseInt(this.newAmount),
                driver: this.driver,
                paymentMethod: this.paymentMethod!,
                manager: this.$getCurrentAccount()
            });

            await this.createTransactionAction(transaction);
            await this.$notifySuccessSimplified('ADD_TRANSACTION.SUCCESS');
            this.dismiss(true);
        } catch (e) {
            this.$notifyErrorSimplified('ADD_TRANSACTION.ERROR');
        } finally {
            this.isLoading = false;
        }
    }

    public checkForm(type: string) {
        this.validData = !this.$v!.$invalid;
        this.triggerValidation(type);
    }

    private dismiss(reload: boolean = false) {
        this.$emit('closeDialog', reload);
    }

    private get driverCredit(): number {
        return this.driver?.credit ?? 0;
    }

}

